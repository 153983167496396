import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { IArticle } from '../interface';
import NavHelper from '../../../utils/NavHelper';
import './Article.scss';

function Article({
  imageAltText,
  image,
  title,
  description,
  publishedDate,
  slug,
}: IArticle) {
  return (
    <div className="article">
      <div className="article__header">
        <h2 className="article__header__title">
          <Link
            to={NavHelper.getSlugUrl('post', slug)}
            className="article__header__title-link"
          >
            {title}
          </Link>
        </h2>
        <div className="article__header__publishedDate">
          {new Date(publishedDate).toLocaleDateString('zh-CN')}
        </div>
      </div>

      <div className="article__summary">
        <GatsbyImage
          alt={imageAltText}
          image={image as IGatsbyImageData}
        />

        <div
          dangerouslySetInnerHTML={{ __html: description }}
          className="article__summary__description"
        />
      </div>
    </div>
  );
}

export default Article;
