import React, { ReactNode } from 'react';
import { getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import ContentContainer from '../components/ContentContainer';
import PostFooter from '../components/PostFooter';
import Summary from '../components/Summary/Summary';
import useSiteMetadata from '../hooks/useSiteMetadata';
import { Layout } from '../components';
import type { GetSummaryQuery } from '../../graphql-types';

export interface IHome {
  data: GetSummaryQuery;
}

export default function Home({
  data,
}: IHome): ReactNode {
  const { title: siteTitle } = useSiteMetadata();

  const articles = data.allMarkdownRemark.edges.map((edge) => {
    const { node } = edge;
    const { frontmatter: e } = node;

    return {
      id: e?.slug,
      title: e?.title,
      image: getImage(e?.thumbnail as any),
      imageAltText: 'thumbnail',
      description: e?.excerpt,
      slug: e?.slug,
      publishedDate: e?.date,
    } as any;
  });

  return (
    <Layout title={siteTitle}>
      <ContentContainer>
        <Summary articles={articles} />
        <PostFooter />
      </ContentContainer>
    </Layout>
  );
}

export const query = graphql`
query GetSummary {
  allMarkdownRemark(
    filter: {frontmatter: {status: {ne: "draft"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        frontmatter {
          title
          excerpt
          date
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 200, placeholder: BLURRED)
            }
          }
          tags
        }
      }
    }
  }
}
`;
