import React from 'react';
import Article from './Article';
import { IArticle } from './interface';
import './Summary.scss';

interface ISummary {
  articles: IArticle[];
}

function Summary({
  articles,
}: ISummary) {
  return (
    <div className="summary">
      {articles.map((article) => {
        return (
          <Article
            key={article.id}
            id={article.id}
            imageAltText={article.imageAltText}
            image={article.image}
            slug={article.slug}
            title={article.title}
            description={article.description}
            publishedDate={article.publishedDate}
          />
        );
      })}
    </div>
  );
}

export default Summary;
